<template>
    <div class="wrap-detail-withdrawal">
        <section>
            <div class="wrap-detail-client">
                <div class="detail-client">
                    <div class="sub-title-page">Detail Client</div>
                    <div class="wrap-client">
                        <div>Client Name</div>
                        <div>:</div>
                        <div>{{ getDataDetail.owner }}</div>
                    </div>
                    <div class="wrap-client">
                        <div>Phone Number</div>
                        <div>:</div>
                        <div>{{ getDataDetail.phonenumber }}</div>
                    </div>
                    <div class="wrap-client">
                        <div>Email</div>
                        <div>:</div>
                        <div>{{ getDataDetail.email }}</div>
                    </div>
                    <div class="wrap-client">
                        <div>Quantity of Pieces</div>
                        <div>:</div>
                        <div>{{ getDataDetail.total_item }}</div>
                    </div>
                    <div class="wrap-client">
                        <div>Total Denom (gr)</div>
                        <div>:</div>
                        <div>{{ getDataDetail.total_weight }}</div>
                    </div>
                    <div class="wrap-client">
                        <div>Status</div>
                        <div>:</div>
                        <div>
                            <span v-show="getDataDetail.status == '001'" class="status-withdrawal kmi">Waiting for KMI Admin Approval</span>
                            <span v-show="getDataDetail.status == '002'" class="status-withdrawal kbi">Waiting for KBI Admin Approval</span>
                            <span v-show="getDataDetail.status == '003'" class="status-withdrawal recipient">Waiting for Upload Recipient Identity</span>
                            <span v-show="getDataDetail.status == '005'" class="status-withdrawal withdrawn">Waiting for Withdraw</span>
                            <span v-show="getDataDetail.status == '004'" class="status-withdrawal operator">Waiting for Operator Confirmation</span>
                            <span v-show="getDataDetail.status == '006'" class="status-withdrawal success">Withdrawal Success</span>
                            <span v-show="getDataDetail.status == '007'" class="status-withdrawal reject">Withdrawal Rejected</span>
                        </div>
                    </div>
                    <div class="wrap-client">
                        <div>Withdrawal Date</div>
                        <div>:</div>
                        <div>{{ getDataDetail.withdrawal_detail.pickup_time | formatDateTime }}</div>
                    </div>
                </div>
                <div class="detail-receipt">
                    <div class="sub-title-page">Detail Recipient</div>
                    <div class="wrap-client">
                        <div>Recipient Name</div>
                        <div>:</div>
                        <div>{{ getDataDetail.withdrawal_detail.recipient ? getDataDetail.withdrawal_detail.recipient.name : "-" }}</div>
                    </div>
                    <div class="wrap-client">
                        <div>Phone Number</div>
                        <div>:</div>
                        <div>{{ getDataDetail.withdrawal_detail.recipient ? getDataDetail.withdrawal_detail.recipient.phone_number : "-" }}</div>
                    </div>
                    <div class="wrap-client">
                        <div>Assignment Letter</div>
                        <div>:</div>
                        <div class="recipient">
                            <span
                                v-if="getDataDetail.withdrawal_detail.attachment.assignment_doc"
                                @click="openModal(getDataDetail.withdrawal_detail.attachment.assignment_doc, 'Assignment Letter')"
                            >
                                {{ getDataDetail.withdrawal_detail.attachment.assignment_doc.split("/").pop() }}
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="wrap-client">
                        <div>Recipient Identity</div>
                        <div>:</div>
                        <div class="recipient">
                            <span
                                v-if="getDataDetail.withdrawal_detail.recipient"
                                @click="openModal(getDataDetail.withdrawal_detail.recipient.ktp_pict, 'Recipient Identity')"
                            >
                                {{ getDataDetail.withdrawal_detail.recipient.ktp_pict.split("/").pop() }}
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="wrap-client">
                        <div>Recipient Selfie</div>
                        <div>:</div>
                        <div class="recipient">
                            <span
                                v-if="getDataDetail.withdrawal_detail.recipient"
                                @click="openModal(getDataDetail.withdrawal_detail.recipient.selfie, 'Recipient Selfie')"
                                >{{ getDataDetail.withdrawal_detail.recipient.selfie.split("/").pop() }}</span
                            >
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="wrap-client">
                        <div>Withdrawal Record</div>
                        <div>:</div>
                        <div class="recipient">
                            <span
                                v-if="getDataDetail.withdrawal_detail.attachment.report_doc"
                                @click="openModal(getDataDetail.withdrawal_detail.attachment.report_doc, 'Withdrawal Record')"
                                >{{ getDataDetail.withdrawal_detail.attachment.report_doc.split("/").pop() }}</span
                            >
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrap-button">
                <button class="btn btn-primary btn-sm" @click="downloadFile(getDataDetail.withdrawal_detail.attachment.withdrawal_doc, 'Apllication Letter')">
                    <b-spinner variant="light" v-if="isLoadingDownload" small></b-spinner>
                    <span v-else>Download Application Letter</span>
                </button>
                <button class="btn btn-success btn-sm" v-if="getDataDetail.status == '002'" @click="approveWithdrawal">
                    <b-spinner variant="light" small v-if="isLoading"></b-spinner>
                    <span v-else>Approve Withdraw</span>
                </button>
            </div>
        </section>
        <section>
            <div class="sub-title-page">List Metal</div>
            <div class="filter">
                <div class="wrap-input">
                    <input type="text" class="form-input" placeholder="Search by serial number" v-model="form.serial_number" @input="filterSerialNumber" />
                    <img src="@/assets/icon_cms/search.svg" alt="" class="icon-input" />
                </div>
                <div class="wrap-input">
                    <dropdown>
                        <template slot="toggler">
                            <div class="form-input" style="width: 12rem">
                                <span v-if="form.year">{{ form.year }}</span>
                                <span v-else style="color: #e5e4e4">Select by Year</span>
                            </div>
                            <img src="@/assets/icon_cms/calendar-alt.svg" alt="" class="icon-input" style="top: 0.65rem" />
                        </template>
                        <dropdown-content>
                            <ul class="dropdown-content">
                                <li v-for="year in yearDatas" :key="year" @click="filterYear(year)">{{ year }}</li>
                            </ul>
                        </dropdown-content>
                    </dropdown>
                </div>
                <button class="button btn-reset btn-small" type="button" v-if="form.serial_number || form.year" @click="resetFilter">Reset</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Serial Number</th>
                        <th>Total Denom (gr)</th>
                        <th>Year</th>
                        <th>Fineness</th>
                        <th>Hallmark</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(detailWithdrawal, i) in getListMetal" :key="i">
                        <td>{{ detailWithdrawal.serial_number }}</td>
                        <td>{{ detailWithdrawal.weight }}</td>
                        <td>{{ detailWithdrawal.year }}</td>
                        <td>{{ detailWithdrawal.fineness }}</td>
                        <td>{{ detailWithdrawal.hallmark }}</td>
                    </tr>
                </tbody>
            </table>
        </section>
        <b-modal ref="preview-modal" :title="titleModal" hide-footer>
            <div class="d-block text-center">
                <div v-if="isPdf">
                    <b-spinner label="Loading..." v-if="isLoadingPreview"></b-spinner>
                    <pdf v-for="i in numPages" :key="i" :src="src" :page="i" v-else />
                </div>
                <div v-else>
                    <b-spinner label="Loading..." v-if="isLoadingPreview"></b-spinner>
                    <img :src="src" alt="" style="width: 100%; object-fit: cover" v-else />
                </div>
            </div>
            <div class="wrap-button mt-3">
                <button class="btn btn-primary btn-small" @click="downloadFile(urlImg, titleModal)">
                    <b-spinner variant="light" small v-if="isLoadingDownload"></b-spinner>
                    <span v-else>Download File</span>
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import pdf from "vue-pdf";
import Dropdown from "../../../../components/dropdown/Dropdown.vue";
import DropdownContent from "../../../../components/dropdown/DropdownContent.vue";
export default {
    components: { Dropdown, DropdownContent, pdf },
    data() {
        return {
            idWithdraw: "",
            isLoading: false,
            modalContent: "",
            titleModal: "",
            isLoadingPreview: false,
            numPages: undefined,
            src: null,
            isPdf: false,
            urlImg: "",
            isLoadingDownload: false,
            form: {
                serial_number: null,
                year: null,
            },
            activity: "deposit",
            perPage: 10,
            currentPage: 1,
            metal_id: "",
            type: "offexchange",
            owner: "",
            status: "",
            yearDatas: [],
        };
    },
    mounted() {
        this.idWithdraw = this.$route.params.id;

        this.$store.dispatch("getWithdrawOffDetail", this.idWithdraw);
        this.$store.dispatch("getMetalList", {
            activity: this.activity,
            perPage: this.perPage,
            currentPage: this.currentPage,
            year: this.form.year,
            metal_id: this.metal_id,
            type: this.type,
            owner: this.owner,
            serialNumber: this.form.serial_number,
            status: this.status,
            withdrawal_id: this.idWithdraw,
        });

        let currentYear = new Date().getFullYear();
        let startYear = 2020;
        while (startYear <= currentYear) {
            this.yearDatas.push(startYear++);
        }
    },
    computed: {
        getDataDetail() {
            return this.$store.state.bullionModule.dataWithdrawal;
        },
        getMetalList() {
            return this.$store.state.bullionModule.dataMetal;
        },
        getListMetal() {
            const dataMetal = this.$store.state.bullionModule.dataWithdrawal.metal_info;
            if (this.form.serial_number) {
                return dataMetal.filter((metal) => {
                    return metal.serial_number.toLowerCase().includes(this.form.serial_number);
                });
            } else if (this.form.year) {
                return dataMetal.filter((metal) => {
                    return metal.year == this.form.year;
                });
            } else {
                return dataMetal;
            }
        },
        transactionId() {
            return this.$store.state.bullionModule.dataWithdrawal.transaction_id;
        },
    },
    methods: {
        resetFilter() {
            this.form = {
                serial_number: "",
                year: "",
            };
        },
        filterYear(option) {
            this.form.year = option;
            this.$store.dispatch("getMetalList", {
                activity: this.activity,
                perPage: this.perPage,
                currentPage: this.currentPage,
                year: this.form.year,
                metal_id: this.metal_id,
                type: this.type,
                owner: this.owner,
                serialNumber: this.form.serial_number,
                status: this.status,
                withdrawal_id: this.idWithdraw,
            });
        },
        filterSerialNumber(event) {
            this.form.serial_number = event.target.value;
            this.$store.dispatch("getMetalList", {
                activity: this.activity,
                perPage: this.perPage,
                currentPage: this.currentPage,
                year: this.form.year,
                metal_id: this.metal_id,
                type: this.type,
                owner: this.owner,
                serialNumber: this.form.serial_number,
                status: this.status,
                withdrawal_id: this.idWithdraw,
            });
        },
        approveWithdrawal() {
            this.isLoading = true;
            this.$store.dispatch("getLocation").then((resGeo) => {
                let info;
                if (resGeo.cityData[0]) {
                    info = JSON.stringify(resGeo.cityData[0]);
                } else {
                    let data = resGeo;
                    data.newLatLong = resGeo.cityLatLong.split(",");
                    info = JSON.stringify(data);
                }
                axios
                    .put("/v2/clearing/admin/offexchange/withdrawal/update", {
                        _id: this.idWithdraw,
                        info: info,
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            this.isLoading = false;
                            this.$toast.success("Withdrawal Has Been Approved");
                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        }
                    })
                    .catch(() => {
                        this.isLoading = false;
                    });
            });
        },
        openModal(file, label) {
            this.$refs["preview-modal"].show();
            this.titleModal = label;
            this.isLoadingPreview = true;
            let newFile = file.replace("/kmi", "/vms");
            this.urlImg = file;
            this.getImage(newFile)
                .then((res) => {
                    if (file.split(".").pop() == "pdf") {
                        this.isPdf = true;
                        this.isLoadingPreview = false;
                        this.src = pdf.createLoadingTask(res);
                        this.src.promise.then((pdf) => {
                            this.numPages = pdf.numPages;
                        });
                    } else {
                        this.isLoadingPreview = false;
                        this.isPdf = false;
                        this.src = res;
                    }
                })
                .catch(() => {
                    this.isLoadingPreview = false;
                });
        },
        async getImage(file) {
            let image = await axios.get("/v2" + file, { responseType: "blob" });
            image = URL.createObjectURL(new Blob([image.data], { type: image.data.type }));
            return image;
        },

        downloadFile(url, title) {
            const typeFile = url.split(".").pop();
            let titleFIle = title + " " + this.getDataDetail.owner + "." + typeFile;
            const newUrl = url.replace("/kmi", "/vms");
            this.isLoadingDownload = true;
            axios
                .get("/v2" + newUrl, { responseType: "arraybuffer" })
                .then((res) => {
                    this.forceFileDownload(res, titleFIle);
                    this.isLoadingDownload = false;
                })
                .catch(() => {
                    this.isLoadingDownload = false;
                    alert("download error");
                });
        },
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", title);
            document.body.appendChild(link);
            link.click();
        },
    },
};
</script>

<style scoped>
.btn {
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.sub-title-page {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}
.wrap-detail-client {
    display: flex;
    gap: 15rem;
    margin-bottom: 1rem;
}
.wrap-button {
    display: flex;
    justify-content: end;
    gap: 0.75rem;
}

.wrap-client {
    display: flex;
    gap: 0.5rem;
    line-height: 2;
}

.dropdown-content {
    margin: unset;
}

.dropdown-content li {
    cursor: pointer;
    padding: 0.5rem;
    line-height: 1;
}

.dropdown-content li:hover {
    background-color: #e5e4e4;
}

.wrap-client div:first-child {
    width: 9rem;
}

.recipient span:first-child {
    cursor: pointer;
    color: #3b82f6;
}
.recipient span:first-child:hover {
    transform: translateY(-1px);
}

.filter {
    margin-bottom: 0.75rem;
    display: flex;
    gap: 0.5rem;
    flex: 0 0 100%;
}

.wrap-input {
    position: relative;
}

.form-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #e5e4e4;
    padding: 8px 2.5rem 8px 1rem;
}

.input-search {
    padding: 8px 2rem 8px 8px;
}

.form-input::placeholder {
    color: #e5e4e4;
}

.form-input:focus {
    outline: none;
}

.icon-input {
    position: absolute;
    font-size: 1rem;
    top: 0.5rem;
    right: 0.75rem;
    color: #8e8e93;
}

.btn-reset {
    background-color: #dc2626;
    color: #fff;
    border-radius: 0.5rem;
}

.kmi {
    color: #cbb574;
}
.kbi {
    color: #eab308;
}
.recipient {
    color: #b2902f;
}
.withdrawn {
    color: #243a72;
}
.operator {
    color: #485a89;
}
.success {
    color: #22c55e;
}
.reject {
    color: #dc2626;
}
</style>
